import PropTypes from 'prop-types';
import Form from './component';
import { defaultPropTypes } from '../propTypes';

Form.defaultProps = {
  onSubmit: () => null
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  form: PropTypes.object.isRequired,
  ...defaultPropTypes
};

export default Form;
