import React, { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { StyledForm } from './styles';

const FormComponent = forwardRef(
  (
    {
      form,
      onSubmit,
      className,
      style,
      children
    },
    ref
  ) => {
    const { handleSubmit } = form;

    return (
      <FormProvider {...form}>
        <StyledForm
          style={style}
          className={className}
          onSubmit={handleSubmit(onSubmit)}
        >
          {children}
        </StyledForm>
      </FormProvider>
    );
  }
);

export default FormComponent;
